.custom-table-data-row {
    font-size: .875rem;
    color: #4F4F51;
    font-weight: 500;
    border-bottom: 1px solid #F4F4F4;
}

.custom-table-data-row>td {
    padding: 12px 10px;
    color: #4F4F51;
    font-size: 14px;
    font-weight: 400;
}

.custom-table-data-row:hover {
    background-color: #0c0b0b0b;
    transition: .3s ease-in-out;
}
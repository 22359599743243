.rdrDateDisplayWrapper {
    width: 100% !important;
    background-color: #ffffff !important;
    padding: 0 !important;
    display: none;
}

.rdrDateDisplay>input {
    width: 100%;
}

.rdrCalendarWrapper, .rdrDateRangeWrapper {
    width: 100% !important;
}

.rdrDateDisplay{
    margin: 0 !important;
}

.rdrDateInput{
    width: 100%;
    color: #424248;
    font-size: 14px;
    outline: 0;
    border: 1px solid #D7D7E3;
    border-radius: 4px;
    cursor: pointer;
}

.rdrDateInput>input{
    width: 100% !important;
    height: 48px;
    padding: 0 16px;
    text-align: left !important;
}
/* Modal Styles */
.overlay {
    width: 100%;
    height: 100vh;
    background: #00000071;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
}

.modal {
    position: fixed;
    z-index: 30;
}

.modal-center {
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    opacity: 1;
    transition: 1s;
}

.modal-right {
    top: 0;
    right: 0;
    opacity: 1;
    transition: 1s;
    display: flex;
    animation: modalRight 0.4s forwards;
    transform: translateZ(0)
}

.modal-right-body {
    height: 89vh;
    padding-bottom: 90px;
    overflow-y: auto;
}

.modal-right-button-container {
    display: flex;
    justify-content: flex-end;
    padding: 16px 0 8px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: #ffffff;
}

/* Slide up */
@keyframes modalRight {
    from {
        transform: translateX(10%);
    }
    to {
        transform: translateX(0);
    }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Inter:wght@100..900&family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Inter:wght@100..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Roboto:wght@500&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.capitalize-first::first-letter {
  text-transform: uppercase;
}

.app-body {
  min-height: 100vh;
  margin-left: 271px;
  padding: 95px 42px 24px;
  animation: slideUp 0.4s forwards;
  transform: translateZ(0)
}

.app-section {
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #f4f4f4;
  padding: 24px;
}

.selected-tab {
  font-weight: 400;
  color: #EA242C;
  border-bottom: 1px solid #EA242C;
}

.unselected-tab {
  font-weight: 400;
  color: #4F4F51;
  border-bottom: 1px solid transparent;
}

/* Customize the scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
  background:rgba(128, 128, 128, 0.084);
}

/* Slide up */
@keyframes slideUp {
  from {
    transform: translateY(2%);
  }

  to {
    transform: translateY(0);
  }
}

.slide-up {
  animation: slideUp 0.4s forwards;
  transform: translateZ(0)
}
.spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;

    border: solid 2px #36A1C5;
    border-top-color: #ffffff;
    border-left-color: #ffffff;
    border-radius: 50%;

    -webkit-animation: spinner 400ms linear infinite;
    animation: spinner 400ms linear infinite;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}